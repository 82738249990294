//main component of applications that acts as a container for other components, it typically includes 
//routing, layout, and overall structurefor your app
import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4'; // Import GA4
import Home from './pages/Home';


function App() {
  useEffect(() => {
    ReactGA.initialize('G-ZDKLD7NWTM'); // Replace with your tracking ID
    ReactGA.send('pageview'); // Record initial page view
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
      </Routes>
    </Router>
  );
}

export default App;